import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";
import type { CoursePageView } from "~/business-areas/course/course.model";

/*
In memory cache for instant loading from course card list to course page

Every course card list hook call will add the loaded courses (CourseCardView[])
to the existing cache after having them converted to CoursePageView to be compatible
and usable on the course page

Upon course page load on client side navigation, the cache is checked and if it exists
the cached course data is used to display the course page while the full page view is loaded.

That allow us to perform an instant page navigation without having to wait
or display a loader before the full page view is loaded.
 */

function toCoursePageView(course: CourseCardView): CoursePageView {
  return {
    ...course,
    audience: [],
    program: [],
    outcomes: [],
    requirements: [],
    og_image_url: undefined as unknown as string,
    teacher: {
      ...course.teacher,
      description: undefined as unknown as string,
      testimonials: [],
    },
    is_masterclass: course.content_type === "MASTERCLASS",
    is_part_of_act_for_impact: course.tracks.some(
      (track) => track.slug === "act-for-impact",
    ),
  };
}

export function useCourseCache() {
  const cache = useState(
    "course_cache",
    () => new Map<string, CoursePageView>(),
  );

  function getBySlug(slug: string) {
    return cache.value.get(slug);
  }

  function setMultiple(courses: CourseCardView[]) {
    for (const course of courses) {
      cache.value.set(course.slug, toCoursePageView(course));
    }
  }

  return {
    getBySlug,
    setMultiple,
  };
}
