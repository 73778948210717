<template>
  <div>
    <nuxt-layout>
      <div class="bg-primary-50">
        <app-page-wrapper
          class="flex !max-w-[1200px] flex-col-reverse items-center justify-between gap-10 py-sections md:flex-row"
        >
          <div
            class="grid gap-4 text-center md:justify-items-start md:text-left"
          >
            <h1 class="font-title text-2xl md:text-4xl" data-cy-error-code>
              {{ $t("app.errors.page_title", { code: error.statusCode }) }}
            </h1>

            <p class="text-xl font-semibold" data-cy-error-message>
              {{ error.message }}
            </p>

            <app-button @click="$router.back()">Page précédente</app-button>
          </div>

          <app-image
            alt=""
            aria-hidden="true"
            class="h-[200px] lg:h-auto"
            :height="345"
            src="/images/illustrations/error.svg"
            :width="460"
          />
        </app-page-wrapper>
      </div>

      <app-page-wrapper v-if="data" class="py-sections">
        <course-card-list-section id="error_page" :list="data" />
      </app-page-wrapper>
    </nuxt-layout>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from "#app";

defineProps<{ error: NuxtError }>();

const { data } = useCourseCardList("error_page", { limit: 10 });
</script>
