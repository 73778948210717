<template>
  <figure
    class="relative aspect-square w-full overflow-hidden rounded-lg @container"
  >
    <app-user-avatar
      :avatar-url="teacher.avatar_url"
      class="absolute inset-0 h-full w-full object-cover transition duration-500 group-hover:scale-105"
      :name="teacher.full_name"
      :size="400"
      tile
    />

    <app-video
      v-if="hovered && videoUrl"
      class="absolute h-full w-full object-cover"
      preview
      :src="videoUrl"
    />

    <figcaption
      class="absolute bottom-0 top-1/3 hidden w-full flex-col justify-end bg-gradient-to-t from-black/50 p-4 text-white transition duration-500 @[150px]:flex"
      :class="{ 'translate-y-2 opacity-0': hovered && videoUrl }"
    >
      <p class="text-xs font-semibold @[170px]:text-sm @[200px]:text-base">
        {{ teacher.full_name }}
        <teacher-pinned-icon v-if="teacher.pinned" :size="14" />
      </p>
      <p v-if="job" class="hidden text-xs @[170px]:block @[200px]:text-sm">
        {{ teacher.job }}
      </p>

      <div class="mt-2 flex gap-2">
        <teacher-companie-logos :companies="teacher.companies" :height="25" />
      </div>
    </figcaption>
  </figure>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    teacher: Pick<
      DatabaseTable<"teachers">,
      "id" | "full_name" | "job" | "companies" | "avatar_url" | "pinned"
    >;
    job?: boolean;
    video?: string | null;
    hovered?: boolean;
  }>(),
  {
    job: true,
    video: undefined,
  },
);

const videoUrl = useCloudinaryVideo(properties.video ?? null, {
  width: 500,
  height: 500,
});
</script>
